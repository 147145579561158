/**
 * Fichier pointant sur le firebase stating ou production
 * Le choix se fait dans le docker
 * Par d�faut c'est le contenu de test qui est pr�sent
 */

let firebaseConfigEnv = {};
switch (process.env.ENV) {
  default:
  case "local":
  case "staging":
  case "demo":
  case "production":
  case "prod":
    firebaseConfigEnv = {
      apiKey: "AIzaSyAShSSTier8x6DZHhy8hXeQxdRtGwYNFJQ",
      authDomain: "eleclibre-7db88.firebaseapp.com",
      projectId: "eleclibre-7db88",
      storageBucket: "eleclibre-7db88.appspot.com",
      messagingSenderId: "137054860190",
      appId: "1:137054860190:web:72c7a723061824d8f5dc8f",
    };
    break;
}

export const firebaseConfig = firebaseConfigEnv;

import {
  HStack,
  // useBreakpointValue
} from "@chakra-ui/react";
import React from "react";

export const LayoutLanding = ({ children }) => {
  // const isSmallDevice = useBreakpointValue(
  //   { base: true, xl: false },
  //   { ssr: false }
  // );

  return (
    <HStack
      minH="100vh"
      height="100%"
      maxWidth="100vw"
      width="100%"
      align="flex-start"
      spacing={0}
    >
      {children}
    </HStack>
  );
};

// import PageDashboardAdmin from "App/admin/pages/PageDashboardAdmin";
// import { Authenticated } from "components/Authenticated/Authenticated";
// import Logout from "App/profil/pages/Logout";
import Page404 from "pages/error/Page404";
import PageUnauthorized from "pages/error/PageUnauthorized";
import Logout from "pages/login/Logout";
import { PageLogin } from "pages/login/PageLogin";
import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import PageLandingPage from "App/landing-page/pages/PageLandingPage";
import PageConfirmationRdv from "App/confirmation/pages/PageConfirmationRdv";

const Routes = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Switch>
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/login" component={PageLogin} />
      {/* <Route exact path="/logout" component={Logout} /> */}
      <Route exact path="/unauthorized" component={PageUnauthorized} />
      <Route exact path="/404" component={Page404} />
      <Route exact path="/" component={PageLandingPage} />
      <Route
       exact
       path="/confirmation-rdv"
       component={PageConfirmationRdv}
     />
      <Route
        exact
        path="/admin"
        component={() => <Redirect to="/admin/dashboard" />}
      />
    
      {/* <Route exact path="/" component={() => <Redirect to="/accueil" />} /> */}
      {/* <Authenticated
        exact
        authorisedUsers={["all"]}
        path="/accueil"
        component={PageAccueil}
      />
      <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN", "ROLE_ENSEIGNEMENT"]}
        path="/absences"
        component={PageAbsences}
      /> */}
      <Route exact path="*" component={() => <Redirect to="/404" />} />
    </Switch>
  );
};

export default Routes;

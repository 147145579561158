import {
  Heading,
  HStack,
  Image,
  Stack,
  Button,
  Box,
  Link as LinkChakra,
  Avatar,
  Text,
  StackDivider,
  useBreakpointValue,
  Icon,
  Wrap,
  List,
  ListItem,
  ListIcon,
  // useDisclosure,
} from "@chakra-ui/react";
import { LayoutLanding } from "App/layout/LayoutLanding";
import React, { useLayoutEffect, useState } from "react";
import { BsCheck, BsStarFill, BsStarHalf } from "react-icons/bs";
import { GiElectric } from "react-icons/gi";
import { Link } from "react-router-dom";
import BoxImageCTA from "../components/BoxImageCTA";
import Footer from "../components/Footer";
// import ModalReservation from "../components/ModalReservation";

const PageLandingPage = () => {
  const isSmallDevice = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [scrollPosition, setScrollPosition] = useState(0);

  // const {
  //   isOpen: isOpenModalReservation,
  //   onOpen: onOpenModalReservation,
  //   onClose: onCloseModalReservation,
  // } = useDisclosure();

  const services = [
    "Déplacement de prises et interrupteurs électriques",
    "Installation d'éclairages",
    "Installation d'éclairages extérieurs",
    "Installation de prises et interrupteurs électriques",
    "Assemblage de composants électriques",
    "Construction générale",
    "Inspections électriques",
    "Installation",
    "Installation de fils de terre",
    "Modification d'installations électriques",
    "Pose d'appareils électriques",
    "Pose de câble électriques",
    "Pose de ventilateurs",
    "Remplacement de fusibles électriques",
    "Réparation de câbles électriques",
    "Réparations générales",
    "Restauration électrique",
  ];

  useLayoutEffect(() => {
    function updatePosition() {
      setScrollPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return (
    <LayoutLanding>
      <Stack
        w="100%"
        maxW="100vw"
        h="100%"
        minH="100vh"
        spacing={0}
        alignItems="center"
      >
        <HStack
          w="100%"
          align="center"
          px={{ base: 30, md: 30, xl: 130 }}
          py={13}
          zIndex={2}
          bgColor="#FFF"
          position="sticky"
          top={0}
          shadow={scrollPosition > 100 ? "xl" : null}
        >
          <Box as={Link} to="/" flex={1}>
            <Image src="/logo_full.png" h={43} alt="logo" />
          </Box>
          <HStack>
            <HStack divider={<StackDivider />}>
              {!isSmallDevice && (
                <Text fontSize="sm" letterSpacing={-0.3}>
                  Appelez maintenant pour votre devis gratuit
                </Text>
              )}
              <Heading
                fontSize="md"
                letterSpacing={-0.5}
                fontWeight={900}
                as={LinkChakra}
                href="tel:+33695450208"
              >
                06.95.45.02.08
              </Heading>
            </HStack>
            {!isSmallDevice && (
              <Avatar
                as={LinkChakra}
                href="tel:+33695450208"
                name="Dan Abrahmov"
                src="/conseiller.png"
              />
            )}
          </HStack>
        </HStack>

        {/* Bar Bottom Bouton Appel */}
        {isSmallDevice && scrollPosition > 200 && (
          <HStack
            w="100%"
            align="center"
            justify="center"
            py={13}
            zIndex={2}
            bgColor="#FFF"
            position="fixed"
            bottom={0}
            boxShadow="base"
          >
            <Button
              bgColor="#fe9103"
              color="white"
              borderRadius={0}
              size="lg"
              as={LinkChakra}
              isExternal
              href="https://zcal.co/i/wlPIxKY0"
              _hover={{ bgColor: "#fd7600" }}
              _active={{ bgColor: "#fd6a01" }}
            >
              Je réalise mon devis gratuit
            </Button>
          </HStack>
        )}

        <Stack
          spacing={0}
          alignItems="center"
          px={{ base: 0, md: 45 }}
          overflow={{ base: "hidden", md: "none" }}
        >
          <Stack
            bgColor="#171717"
            backgroundImage="url(/media/circuit.svg)"
            backgroundPosition="0 0"
            backgroundSize="auto"
            color="#FFF"
            py={70}
            pb={isSmallDevice ? 140 : 70}
            borderRadius={{ base: 0, md: "xl" }}
            w="100%"
            justify="center"
            align="center"
            spacing={9}
            position="relative"
          >
            <Stack justify="center" align="center">
              <Heading fontSize="xl">Le Havre, FR</Heading>
              <Heading maxW="80%" textAlign="center">
                <span style={{ color: "#fe9103" }}>
                  + de 34 années d'expérience
                </span>
                {" à votre service pour des installations"}
                <span style={{ color: "#fe9103" }}> rapides</span> et
                <span style={{ color: "#fe9103" }}> fiables</span>
              </Heading>
            </Stack>
            <Button
              bgColor="#fe9103"
              borderRadius={0}
              size="lg"
              as={LinkChakra}
              isExternal
              href="https://zcal.co/i/wlPIxKY0"
              _hover={{ bgColor: "#fd7600", textDecoration: "none" }}
              _active={{ bgColor: "#fd6a01" }}
            >
              Je réalise mon devis gratuit
            </Button>
            <Stack justify="center" align="center">
              <HStack>
                <Icon as={BsStarFill} />
                <Icon as={BsStarFill} />
                <Icon as={BsStarFill} />
                <Icon as={BsStarFill} />
                <Icon as={BsStarHalf} />
              </HStack>
              <Heading fontSize="xl" textDecoration="underline">
                Actuellement Disponible
              </Heading>
            </Stack>
            <Wrap justify="center" align="center">
              <HStack maxW="40%">
                <Icon as={BsCheck} />
                <Text color="#FFF" fontSize="sm" fontWeight={200} opacity={0.9}>
                  Devis sans engagement
                </Text>
              </HStack>
              <HStack maxW="40%">
                <Icon as={BsCheck} />
                <Text color="#FFF" fontSize="sm" fontWeight={200} opacity={0.9}>
                  Service les soirs et week-end
                </Text>
              </HStack>
              <HStack maxW="40%">
                <Icon as={BsCheck} />
                <Text color="#FFF" fontSize="sm" fontWeight={200} opacity={0.9}>
                  RDV le jour même ou le lendemain
                </Text>
              </HStack>
              <HStack maxW="40%">
                <Icon as={BsCheck} />
                <Text color="#FFF" fontSize="sm" fontWeight={200} opacity={0.9}>
                  Prix transparents et abordables
                </Text>
              </HStack>
            </Wrap>
            {isSmallDevice && (
              <Box position="absolute" bottom="0" left="50%">
                <Image
                  src="/media/banner-image.jpg"
                  minW={300}
                  w="70vw"
                  alt="logo"
                  shadow="2xl"
                  transform="translate(-50%, 50%)"
                />
              </Box>
            )}
          </Stack>
          <Stack
            pt={isSmallDevice ? 120 : 30}
            px={{ base: 45, md: 0 }}
            direction="row"
          >
            {!isSmallDevice && (
              <Stack px={20} flex={1}>
                <BoxImageCTA />
              </Stack>
            )}
            <Stack spacing={6} justify="center" align="center" flex={2}>
              <Stack spacing={0} justify="center" align="center">
                <Heading as="h3" textAlign="center" fontSize="xl">
                  Services électriques en tout genre
                </Heading>
                <Text
                  fontSize="lg"
                  fontWeight={200}
                  textAlign="center"
                  color="gray.600"
                >
                  Réparations, Installations, Mises à Niveau, Branchements et
                  Câblage
                </Text>
              </Stack>
              <Stack>
                <Text fontSize="sm" fontWeight="700">
                  Vous cherchez un électricien au Havre ou dans les environs ?
                  Vous êtes au bon endroit !
                </Text>
                <Text fontSize="sm">
                  Des installations aux réparations, câblage et mises à niveau,{" "}
                  <span style={{ fontWeight: 700 }}>nos électriciens</span>{" "}
                  peuvent <span style={{ fontWeight: 700 }}>vous aider</span>{" "}
                  avec l'un des services suivants :
                </Text>
                <List
                  spacing={3}
                  fontSize="sm"
                  css={isSmallDevice ? null : { columns: "2" }}
                >
                  {services?.map((element) => (
                    <ListItem key={element} color="gray.800" fontStyle="italic">
                      <ListIcon as={GiElectric} color="gray.800" />
                      {element}
                    </ListItem>
                  ))}
                </List>
              </Stack>
              <Stack>
                <Text fontSize="sm">
                  Quels que soient vos besoins en matière de services
                  électriques,{" "}
                  <span style={{ fontWeight: 700 }}>
                    Eleclibre est la seule entreprise que vous aurez à appeler.
                  </span>
                </Text>
                <Text fontSize="sm">
                  Contactez-nous dès aujourd'hui au{" "}
                  <Heading
                    fontSize="md"
                    letterSpacing={-0.5}
                    fontWeight={900}
                    as={LinkChakra}
                    href="tel:+33695450208"
                  >
                    06.95.45.02.08
                  </Heading>{" "}
                  pour un service 7j/7 et un devis sans engagement.
                </Text>
                {/* https://zcal.co/i/wlPIxKY0 */}
                {/* <Text>
                Pourquoi faire appel à des électriciens professionnels ?
                Engagement envers la qualité, prix raisonnables et souci du
                détail… Les propriétaires de Mountain View préfèrent les
                électriciens professionnels aux autres entreprises d'électricité
                pour de nombreuses raisons. Nos clients nous font confiance car
                : Devis transparents et immédiats Même jour, service 24 heures
                sur 24 Entrepreneurs certifiés et agréés Arrivée rapide et
                ponctuelle Satisfaction garantie à 100 % Des prix toujours
                abordables Lorsque nos clients ont besoin d'un service
                d'électricien rapide et fiable, ils savent qu'il leur suffit de
                décrocher le téléphone — et nous ferons le reste. Contactez-nous
                aujourd'hui au (888) 284-3712 pour planifier votre rendez-vous.
              </Text>
              <Text>
                Electrician Pros : le nom de confiance pour les services
                d'électricien à Mountain View Chez Electrician Pros, nous nous
                efforçons d'offrir les meilleurs services d'électricien dans la
                région de Mountain View. Nous savons à quel point il peut être
                difficile de trouver un électricien local qui valorise votre
                temps, offre un service cinq étoiles et traite votre maison avec
                respect. De la conversation initiale jusqu'à la fin de votre
                travail, notre équipe a un objectif en tête : vous offrir une
                expérience client exceptionnelle à un prix abordable, sans
                rogner. Vous pouvez compter sur les professionnels de confiance
                d'Electrician Pros pour : Devis sans risque Oubliez les frais
                surprises ou les coûts cachés. Avant le début des travaux, votre
                électricien fournira des prix précis et initiaux qui décrivent
                l'étendue des travaux et les pièces nécessaires à votre projet.
                Service rapide le jour même Planifier un électricien devrait
                être simple ! Nous savons que votre temps est précieux. C'est
                pourquoi nous offrons un service client 24h/24 et 7j/7, un
                service le jour même et des horaires de rendez-vous flexibles
                qui s'adaptent à votre emploi du temps. Réparations d'urgence 24
                heures sur 24 Nous comprenons à quel point il est important de
                remettre votre système électrique en marche rapidement. Pour
                mieux vous servir, nous avons des techniciens disponibles 24
                heures sur 24, 7 jours sur 7 - Et nos camions sont entièrement
                approvisionnés, nous sommes donc prêts à résoudre tout problème
                électrique domestique en une seule visite. Service d'électricien
                Mountain View près de chez moi Avec des électriciens locaux dans
                toute la Californie, Electrician Pros est votre meilleure option
                pour un service rapide, peu importe où vous vous trouvez. Que
                vous ayez besoin d'une réparation, d'une mise à niveau ou d'une
                installation, nos experts en électricité locaux peuvent vous
                aider. Service client le mieux noté Électriciens certifiés,
                professionnels sympathiques, ponctualité et travail de qualité.
                Du début à la fin, nous assurer que vous êtes satisfait de nos
                services électriques est notre priorité absolue. Nous avons un
                électricien près de chez vous ! Appelez-nous aujourd'hui au
                (888) 284-3712 pour planifier le service et obtenir un devis
                sans obligation.
              </Text> */}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Footer />
      </Stack>
      {/* <ModalReservation isOpen={isOpenModalReservation} onClose={onCloseModalReservation} /> */}
    </LayoutLanding>
  );
};

export default PageLandingPage;

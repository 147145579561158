import React, { StrictMode } from "react";
import "config/dayjs";
import "config/axios";
import "./bodyStyles.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { customTheme } from "theme/customTheme";
import { ChakraProvider } from "@chakra-ui/react";
import ReactGA from "react-ga";
// import TagManager from 'react-gtm-module'

const TRACKING_ID = "UA-242597247-2"; // OUR_TRACKING_ID

// const tagManagerArgs = {
//   gtmId: 'AW-11042125038'
// }

// TagManager.initialize(tagManagerArgs)

ReactGA.initialize(TRACKING_ID);

const queryClient = new QueryClient();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <ChakraProvider resetCSS theme={customTheme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <App />
      </QueryClientProvider>
    </ChakraProvider>
  </StrictMode>
);

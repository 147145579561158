import {
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  Link as LinkChakra,
  Divider,
} from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  const isSmallDevice = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Stack w="100%" position="absolute" bottom={0}>
      <Stack
        bgColor="#171717"
        backgroundImage="url(/media/circuit.svg)"
        backgroundPosition="0 0"
        backgroundSize="auto"
        color="#FFF"
        py={70}
        pb={isSmallDevice ? 140 : 70}
        w="100%"
        justify="center"
        align="center"
        spacing={9}
        position="relative"
      >
        <Heading
          fontSize="xl"
          letterSpacing={-0.5}
          fontWeight={900}
          as={LinkChakra}
          href="mailto:contact@eleclibre.com"
        >
          contact@eleclibre.com
        </Heading>
        <Divider opacity={0.3} />
        <Stack spacing={0} align="center" justify="center">
          <Text fontSize="sm" fontWeight="700" color="white">
            ELECLIBRE
          </Text>
          <Text fontSize="xs" fontWeight="300" color="white">
            © 2022 eleclibre.com — Tous droits réservés
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;

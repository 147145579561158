import { extendTheme } from "@chakra-ui/react";

const colors = {
  // Go to https://smart-swatch.netlify.com/ to easily generate a new color palette.
  brand: {
    500: "#3a82f7",
    bleuCiel: "#3a82f7",
    grisClair: "#f5f5f5",
    grisBackground: "#f8f8f8",
  },
};

const shadows = {
  vertElectrique: "0 0 10px 1px rgba(18, 227, 74, 0.7)",
};

const Text = {
  baseStyle: {
    color: "gray.800",
  },
};

const ListItem = {
  baseStyle: {
    color: "gray.800",
  },
};

export const customTheme = extendTheme({
  components: {
    Text,
    ListItem
  },
  colors,
  shadows,
});

import {
  Heading,
  Stack,
  Text,
  Link as LinkChakra,
  Button,
  Image,
  Box,
} from "@chakra-ui/react";
import React from "react";

const BoxImageCTA = () => {
  return (
    <Box position="sticky" top="0">
      <Image src="/media/banner-image.jpg" w="100%" alt="logo" shadow="2xl" />
      <Stack bgColor="#f5f5f5" spacing={5} p={5} justify="center" align="center">
        <Heading as="h4" fontSize="md" textAlign="center">
          Services électriques Le Havre
        </Heading>
        <Stack spacing={1} align="center" justify="center">
          <Text fontSize="md" textAlign="center" fontWeight="200">
            Installation et réparation le soir même
          </Text>
          <Text fontSize="md" textAlign="center" fontWeight="200">
            Appelez maintenant pour planifier un RDV
          </Text>
        </Stack>
        <Button
          bgColor="#fe9103"
          borderRadius={0}
          size="lg"
          w="100%"
          as={LinkChakra}
          href="https://zcal.co/i/wlPIxKY0"
          isExternal
          _hover={{ bgColor: "#fd7600", textDecoration: "none" }}
          _active={{ bgColor: "#fd6a01" }}
          // fontSize="xl"
          // fontWeight={900}
          // letterSpacing={-0.5}
          color="white"
        >
          Demande de devis gratuit
        </Button>
      </Stack>
    </Box>
  );
};

export default BoxImageCTA;

import {
  Heading,
  HStack,
  Image,
  Stack,
  Box,
  Link as LinkChakra,
  Avatar,
  StackDivider,
  useBreakpointValue,
} from "@chakra-ui/react";
import { LayoutLanding } from "App/layout/LayoutLanding";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const PageConfirmationRdv = () => {
  const isSmallDevice = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <LayoutLanding>
      <Stack
        w="100%"
        maxW="100vw"
        h="100%"
        minH="100vh"
        spacing={0}
        alignItems="center"
      >
        <HStack
          w="100%"
          align="center"
          px={{ base: 30, md: 30, xl: 130 }}
          py={13}
          zIndex={2}
          bgColor="#FFF"
          position="sticky"
          top={0}
        >
          <Box as={Link} to="/" flex={1}>
            <Image src="/logo_full.png" h={43} alt="logo" />
          </Box>
          <HStack>
            <HStack divider={<StackDivider />}>
              <Heading
                fontSize="md"
                letterSpacing={-0.5}
                fontWeight={900}
                as={LinkChakra}
                href="tel:+33695450208"
              >
                06.95.45.02.08
              </Heading>
            </HStack>
            {!isSmallDevice && (
              <Avatar
                as={LinkChakra}
                href="tel:+33695450208"
                name="Dan Abrahmov"
                src="/conseiller.png"
              />
            )}
          </HStack>
        </HStack>

        <Stack
          spacing={0}
          alignItems="center"
          px={{ base: 0, md: 45 }}
          overflow={{ base: "hidden", md: "none" }}
        >
          <Stack
            bgColor="#171717"
            backgroundImage="url(/media/circuit.svg)"
            backgroundPosition="0 0"
            backgroundSize="auto"
            color="#FFF"
            py={70}
            pb={70}
            borderRadius={{ base: 0, md: "xl" }}
            w="100%"
            justify="center"
            align="center"
            spacing={9}
            position="relative"
          >
            <Stack justify="center" align="center">
              <Heading maxW="80%" textAlign="center">
                Confirmation de votre rendez-vous
              </Heading>
              <Heading fontSize="xl" color="#fe9103">
                À très bientôt avec Eleclibre !
              </Heading>
            </Stack>
          </Stack>
        </Stack>
        <Footer isSmall />
      </Stack>
    </LayoutLanding>
  );
};

export default PageConfirmationRdv;

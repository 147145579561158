import {
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  Link as LinkChakra,
  Button,
  Divider,
} from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  const isSmallDevice = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Stack w="100%" pt={61}>
      <Stack
        bgColor="#171717"
        backgroundImage="url(/media/circuit.svg)"
        backgroundPosition="0 0"
        backgroundSize="auto"
        color="#FFF"
        py={70}
        pb={isSmallDevice ? 140 : 70}
        w="100%"
        justify="center"
        align="center"
        spacing={9}
        position="relative"
      >
        <Stack w="100%" justify="center" align="center" color="white">
          <Heading fontSize="xl">Le Havre, FR</Heading>
          <Heading maxW="80%" textAlign="center">
            {" Votre électricien "}
            <span style={{ color: "#fe9103" }}>à votre service</span>
          </Heading>
          <Stack spacing={0} align="center" justify="center">
            <Text fontSize="lg" fontWeight="200" color="white">
              Installation et réparation le soir même{" "}
            </Text>
            <Text fontSize="lg" fontWeight="200" color="white">
              Appelez maintenant :{" "}
              <Heading
                fontSize="xl"
                letterSpacing={-0.5}
                fontWeight={900}
                as={LinkChakra}
                href="tel:+33695450208"
              >
                06.95.45.02.08
              </Heading>
            </Text>
          </Stack>
        </Stack>
        <Button
          bgColor="#fe9103"
          borderRadius={0}
          size="lg"
          as={LinkChakra}
          href="https://zcal.co/i/wlPIxKY0"
          isExternal
          _hover={{ bgColor: "#fd7600", textDecoration: "none" }}
          _active={{ bgColor: "#fd6a01" }}
        >
          Je réalise mon devis gratuit
        </Button>
        <Heading
          fontSize="xl"
          letterSpacing={-0.5}
          fontWeight={900}
          as={LinkChakra}
          href="mailto:contact@eleclibre.com"
        >
          contact@eleclibre.com
        </Heading>
        <Divider opacity={0.3} />
        <Stack spacing={0} align="center" justify="center">
          <Text fontSize="sm" fontWeight="700" color="white">
            ELECLIBRE
          </Text>
          <Text fontSize="xs" fontWeight="300" color="white">
            © 2022 eleclibre.com — Tous droits réservés
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;

import React, { useState } from "react";
import { Button, Stack, Text, useToast } from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import { isEmail, isMinLength } from "@formiz/validations";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { FieldInput } from "components/fields/FieldInput";
import PropTypes from "prop-types";
import { toastError } from "functions/toast";
import { useLocation } from "react-router-dom";

export const FormLogin = ({ setIsReseting, setIsErrorLogin }) => {
  const loginForm = useForm({
    subscribe: "form",
  });

  const toast = useToast();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickResetPassword = () => setIsReseting(true);
  const auth = getAuth();

  const handleConnexion = (values) => {
    setIsLoading(true);
    signInWithEmailAndPassword(
      auth,
      values.email.toLowerCase(),
      values.password
    )
      .then(() => {})
      .catch(async (error) => {
        await signOut(auth)
          .then(() => {
            toastError(toast, {
              title: "Connexion échouée",
              description: "Impossible de se connecter",
            });
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      });
  };

  return (
    <>
      <Formiz connect={loginForm} onValidSubmit={handleConnexion}>
        <form noValidate onSubmit={loginForm.submit}>
          <Stack spacing="6">
            {/* Formulaire de login */}
            <FieldInput
              w={{
                base: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
              }}
              name="email"
              label="Email"
              required="Email requis"
              placeholder="nom@company.com"
              defaultValue={params.get("email") ?? ""}
              validations={[
                {
                  rule: isEmail(),
                  message: "Vérifier le format de votre adresse email",
                },
              ]}
            />

            {/* Formulaire du mot de passe  */}

            <FieldInput
              w={{
                base: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
              }}
              type="password"
              name="password"
              label="Mot de passe"
              required="Password requis"
              validations={[
                {
                  rule: isMinLength(6),
                  message: "Le mot de passe doit contenir au moins 6 car",
                },
              ]}
            />

            <Button
              isLoading={isLoading}
              type="button"
              onClick={() => loginForm.submit()}
              colorScheme="twitter"
              w="100%"
            >
              SE CONNECTER
            </Button>
          </Stack>
        </form>
      </Formiz>
      <Text
        as="button"
        my={2}
        color="gray.900"
        textAlign="left"
        fontSize="0.8rem"
        fontStyle="italic"
        textDecoration="underline"
        onClick={handleClickResetPassword}
        w="100%"
      >
        Réinitialiser le mot de passe
      </Text>
    </>
  );
};

FormLogin.propTypes = {
  setIsReseting: PropTypes.func.isRequired,
  setIsErrorLogin: PropTypes.func.isRequired,
};
